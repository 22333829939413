.webviewText {
  display: flex;
  flex-direction: column;
  padding: 20px;
  button {
    padding: 10px;
    max-width: 200px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .haptic {
    button {
      margin-right: 10px;
    }
  }
}
